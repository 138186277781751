import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/webprojectcard.css"
import Particle12 from "../components/Particle12"
import SEO from "../components/SEO"
import { Link } from "gatsby"

const sorting = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="Sorting Programs"
          description="Programs of Sorting under Data Structures and Algorithm with Python."
        />
        <Particle12></Particle12>
        <h6>s</h6>
        <section className="noSelect">
          <Link to="/dsa/">
            <button type="button" className="back-btn noSelect">
              <span>{"<"}= More Programs</span>
            </button>
          </Link>
        </section>

        <div className="programming-project">
          <h1>Sorting</h1>
        </div>

        <section className="most-liked noSelect">
          <h3 className="total">Total Programs :- 5</h3>
          <div className="row">
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Bubble Sort</h3>
                </div>
                <p className="basic-op">
                  •Simple sorting algorithm
                  <br />
                  •Compares adjacent elements and swaps them if they are in the
                  wrong order
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Sorting/Bubble%20Sort.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Insertion Sort</h3>
                </div>
                <p className="basic-op">
                  •Array is split into sorted and an unsorted part
                  <br />• Now sort the unsorted part, one item at a time,then
                  add among the sorted part
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Sorting/Insertion%20Sort.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Selection Sort</h3>
                </div>
                <p className="basic-op">
                  •Keep finding minimum elememt and put in start
                  <br />
                  •Ultimately, in start we have sorted part in end we have
                  unsorted part
                  <br />
                  •Finally, sorting completed
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Sorting/Selection%20Sort.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Quick Sort</h3>
                </div>
                <p className="basic-op">
                  •Divide and Conquer algo
                  <br />
                  •Select a 'pivot' element from the array
                  <br />
                  •Partition other elements in two sub-arrays, on basis of less
                  or greater from pivot
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Sorting/Quick%20Sort.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Merge Sort</h3>
                </div>
                <p className="basic-op">
                  •Divide and Conquer algo
                  <br />
                  •Cut the given array into 2 sub-array, keep cutting, in last
                  compare elements one with another
                  <br />
                  •Merge the two half arrays
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Sorting/Merge%20Sort.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default sorting
